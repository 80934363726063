<template>
    <div v-if="!textualEdit && !forceTextualEdit">
        <div v-if="filterItems.mode == 'and'">
            <table class="mb-1 w-100">
                <thead>
                    <tr style="background-color:lightsteelblue;">
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:6%;"></th>
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:27%">{{ $t("Fieldname") }}</th>
                        <th style="border: solid 1px silver;font-size:small;width:25%">{{ $t("Criteria") }}</th>
                        <th style="border: solid 1px silver;font-size:small;width:35%">{{ $t("Value") }}</th>
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:5%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(andItem,index2) in filterItems.items">
                        <td class="text-center">
                            <span v-if="index2 > 0">{{$t('And')}}</span>
                        </td>
                        <td>
                            <ODataLookup v-if="chooseFields.find(obj=>obj.FieldName == andItem.column)"
                                :bind="sel => fieldsLkpBind(andItem, sel)"
                                :data-object="dsFieldsLkp"
                                noClear>
                                <OColumn name="Caption" :headerName="$t('Fieldname')" width="250"></OColumn>
                                <template #target="{ target }">
                                    <!-- <input :ref="target" class="form-control form-control-sm" :value="((item) => item ? `${item.Caption}${item.IsProp ? ' ('+$t('Property')+')' : ''}` : '')(chooseFields.find(obj => obj.FieldName == andItem.column))"> -->
                                    <input :ref="target" class="form-control form-control-sm" :value="chooseFields.find(obj=>obj.FieldName == andItem.column).Caption">
                                </template>
                                <template #toolbarActions v-if="props.propertiesDataObject">
                                    <OToolbarAction :text="$t('Properties')" v-model="fieldsLkpToggle"/>
                                </template> 
                            </ODataLookup>

                        </td>
                        <td>
                            <select v-model="andItem.operator" class="form-control form-control-sm">
                                <option :value="op.name" v-for="op in getOperatorsList2(andItem.valueType,andItem.column)">
                                    {{ op.title }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <div class="input-group">
                                <!-- <input v-if="getType(andItem.valueType,andItem.column) == 'number'" v-model="andItem.value" type="text" pattern="^[0-9,]*$" class="form-control form-control-sm"> -->
                                <input v-model="andItem.value" type="text" :disabled="getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1" class="form-control form-control-sm">
                                <ODataLookup v-if="props.lookups[andItem.column] && !(getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1)" :bind="sel => handleLookupSelection(sel, andItem)"
                                    :data-object="props.lookups[andItem.column].DataObject"
                                    :whereClause="props.lookups[andItem.column].WhereClause"
                                    :columns="props.lookups[andItem.column]?.Columns ? props.lookups[andItem.column].Columns : [{field:props.lookups[andItem.column].Column}]"
                                    :key="andItem.column+index2"
                                    >
                                    <!-- <OColumn v-for="a in props.lookups[andItem.column]?.Columns ? props.lookups[andItem.column].Columns : [{field:props.lookups[andItem.column].Column}]" :field="a.field"></OColumn> -->
                                    <template #target="{ target }">
                                        <button :ref="target" class="btn btn-link btn-sm">
                                            <i class="bi bi-chevron-down"></i>
                                        </button>
                                    </template>
                                </ODataLookup>
                            </div>
                        </td>
                        <td class="text-center">
                            <i class="bi bi-x" style="cursor:pointer;" @click="deleteItem(null,index2)"></i>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center">
                            <span v-if="filterItems.items.length">{{$t('And')}}</span>
                        </td>
                        <td>
                            <ODataLookup
                                :bind="sel => setTypeAndOperator(null, sel.FieldName)"
                                :data-object="dsFieldsLkp"
                                noClear>
                                <OColumn name="Caption" :headerName="$t('Fieldname')" width="250"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" class="form-control form-control-sm" :placeholder="$t('Select field')">
                                </template>
                                <template #toolbarActions v-if="props.propertiesDataObject">
                                    <OToolbarAction :text="$t('Properties')" v-model="fieldsLkpToggle"/>
                                </template> 
                            </ODataLookup>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="filterItems.items.length">
                <button class="btn btn-outline-primary btn-sm" @click="newOr = true" :disabled="newOr">{{ $t('Or (add group)') }}</button>
            </div>

        </div>

        <div v-for="(orGroup,index) in filterItems.items" v-else-if="filterItems.mode == 'or'">
            <div v-if="index > 0">{{$t('Or')}} <i class="bi bi-x" style="cursor:pointer;" @click="deleteGroup(index)"></i></div>
            <table class="mb-3 w-100">
                <thead v-if="index == 0">
                    <tr style="background-color:lightsteelblue;">
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:6%;"></th>
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:27%">{{ $t("Fieldname") }}</th>
                        <th style="border: solid 1px silver;font-size:small;width:25%">{{ $t("Criteria") }}</th>
                        <th style="border: solid 1px silver;font-size:small;width:35%">{{ $t("Value") }}</th>
                        <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:5%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(andItem,index2) in orGroup.items">
                        <td class="text-center" style="width:6%;">
                            <span v-if="index2 > 0">{{$t('And')}}</span>
                        </td>
                        <td style="width:27%;">
                            <ODataLookup v-if="chooseFields.find(obj=>obj.FieldName == andItem.column)"
                                :bind="sel => fieldsLkpBind(andItem, sel)"
                                :data-object="dsFieldsLkp"
                                noClear>
                                <OColumn name="Caption" :headerName="$t('Fieldname')" width="250"></OColumn>
                                <template #target="{ target }">
                                    <!-- <input :ref="target" class="form-control form-control-sm" :value="((item) => item ? `${item.Caption}${item.IsProp ? ' ('+$t('Property')+')' : ''}` : '')(chooseFields.find(obj => obj.FieldName == andItem.column))"> -->
                                        <input :ref="target" class="form-control form-control-sm" :value="chooseFields.find(obj=>obj.FieldName == andItem.column).Caption">
                                </template>
                                <template #toolbarActions v-if="props.propertiesDataObject">
                                    <OToolbarAction :text="$t('Properties')" v-model="fieldsLkpToggle"/>
                                </template>
                            </ODataLookup>
                        </td>
                        <td style="width:25%;">
                            <select v-model="andItem.operator" class="form-control form-control-sm">
                                <option :value="op.name" v-for="op in getOperatorsList2(andItem.valueType,andItem.column)">
                                    {{ op.title }}
                                </option>
                            </select>
                        </td>
                        <td style="width:35%;">
                            <div class="input-group">
                                <!-- <input v-if="getType(andItem.valueType,andItem.column) == 'number'" v-model="andItem.value" type="text" pattern="^[0-9,]*$" class="form-control form-control-sm"> -->
                                <input v-model="andItem.value" type="text" :disabled="getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1" class="form-control form-control-sm">
                                <ODataLookup v-if="props.lookups[andItem.column] && !(getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1)" :bind="sel => handleLookupSelection(sel, andItem)"
                                    :data-object="props.lookups[andItem.column].DataObject"
                                    :whereClause="props.lookups[andItem.column].WhereClause"
                                    :columns="props.lookups[andItem.column]?.Columns ? props.lookups[andItem.column].Columns : [{field:props.lookups[andItem.column].Column}]"
                                    :key="andItem.column+index2"
                                    >
                                    <template #target="{ target }">
                                        <button :ref="target" class="btn btn-link btn-sm">
                                            <i class="bi bi-chevron-down"></i>
                                        </button>
                                    </template> 
                                </ODataLookup>
                            </div>
                        </td>
                        <td class="text-center" style="width:5%;">
                            <i class="bi bi-x" style="cursor:pointer;" @click="deleteItem(index,index2)"></i>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center" style="width:6%;">
                            <span v-if="orGroup.items.length">{{$t('And')}}</span>
                        </td>
                        <td style="width:27%;">
                            <ODataLookup
                                :bind="sel => setTypeAndOperator(null, sel.FieldName, index)"
                                :data-object="dsFieldsLkp"
                                noClear>
                                <OColumn name="Caption" :headerName="$t('Fieldname')" width="250"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" class="form-control form-control-sm" :placeholder="$t('Select field')">
                                </template>
                                <template #toolbarActions v-if="props.propertiesDataObject">
                                    <OToolbarAction :text="$t('Properties')" v-model="fieldsLkpToggle"/>
                                </template> 
                            </ODataLookup>
                        </td>
                        <td style="width:25%;"></td>
                        <td style="width:35%;"></td>
                        <td style="width:5%;"></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="index == filterItems.items.length-1 && filterItems.items[index].items.length">
            <button class="btn btn-outline-primary btn-sm" @click="newOr = true" :disabled="newOr">{{ $t('Or (add group)') }}</button>
            </div>
        </div>

        <div v-if="newOr">
            <div>{{$t('Or')}} <i class="bi bi-x" style="cursor:pointer;" @click="newOr = false"></i></div>
            <table class="mb-3 w-100">

                <tbody>
                    <tr>
                        <td class="text-center" style="width:6%;">
                        </td>
                        <td style="width:27%;">
                            <ODataLookup
                                :bind="sel => setTypeAndOperator(null, sel.FieldName, null, true)"
                                :data-object="dsFieldsLkp"
                                noClear>
                                <OColumn name="Caption" :headerName="$t('Fieldname')" width="250"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" class="form-control form-control-sm" :placeholder="$t('Select field')">
                                </template>
                                <template #toolbarActions v-if="props.propertiesDataObject">
                                    <OToolbarAction :text="$t('Properties')" v-model="fieldsLkpToggle"/>
                                </template> 
                            </ODataLookup>
                        </td>
                        <td style="width:25%;"></td>
                        <td style="width:35%;"></td>
                        <td style="width:5%;" class="text-center">
                            <!-- <i class="bi bi-x" style="cursor:pointer;" @click="newOr = false"></i> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    <div v-else>
        <span v-if="forceTextualEdit">{{$t('Could not set up filter builder')+'.'}}</span>
        <span> {{$t('Edit textually')+':'}}</span>
        <OTextArea v-model="filterStringTextEdit" class="form-control from-control-sm" autocomplete="false" spellcheck="false" autoGrow noResize/>
    </div>

    <div class="d-flex" v-if="!forceTextualEdit">
        
        <div class="form-check form-switch float-end form-check-inline ms-auto" :title="$t('Toggle textual edit')">
            <label class="form-check-label">
                {{$t('Text')}}
                <input class="form-check-input" type="checkbox" role="switch" @change="toggleTextualEdit">
            </label>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted,watch } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject'; 
import { getOrCreateProcedure } from 'o365-modules';
import { filterUtils, Devex} from 'o365-filterobject';
import { API } from 'o365-modules';
// todo: create own function getOperatorsList supporting options from ct, add ISNULL(,) for NOT criteria where missing, add int+date editors for these types, ensure this component works despite changes in o365-filterobject
const { createInitialObject, filterItemsToString, prettifyFilterItems, /*filterStringToFilterItems,*/ getOperatorTitle, getOperatorsList, getDefaultOperator } = filterUtils;

const props = defineProps({
    filterString: String,
    fieldsDataObject: Object, // fetching fields from schema
    propertiesDataObject: Object, // fetching fields from column values
    //propertiesViewName: String,
    lookups: Object

});

const dsFieldsLkp = getOrCreateDataObject({
    id: 'dsFieldsLkp_FilterBuilder',
    //maxRecords?
    fields: [{name: "FieldName", type:"string"}, {name: "Caption", type:"string"}, {name: "Type", type:"string"}]
});

const fieldsLkpBind = (row, sel)=>{
    row.column = sel.FieldName;
    setTypeAndOperator(row,sel.FieldName)
}

const chooseFields = ref([]);
const textualEdit = ref(false);
const forceTextualEdit = computed(()=>{
    return filterItems.value.type == 'literal'
});
const fieldsLkpToggle = ref(false);

watch(fieldsLkpToggle,()=>{
    dsFieldsLkp.enableClientSideHandler( fieldsLkpToggle.value ? chooseFields.value.filter(r => r.IsProp) : chooseFields.value.filter(r => !r.IsProp) );
    dsFieldsLkp.load();
});

let fieldTypesMapping = {};

const initiateFields = async() =>{
    let vDataFields = [], vDataProps = [];

    if (props.fieldsDataObject){
        vDataFields = props.fieldsDataObject.fields.fields.map(f => {return {FieldName: f.field, Caption: f.caption, Type: f.type, IsProp: false}});
    }
    if (props.propertiesDataObject){
        if (!props.propertiesDataObject.state.isLoaded){
            await props.propertiesDataObject.load();
        }
        vDataProps = props.propertiesDataObject.data.map(r => {return {FieldName: 'Prop_'+r.Name, Caption: r.Name, Type: r.DataType, IsProp: true}});
    }
    chooseFields.value = [...vDataFields, ...vDataProps].sort((a,b)=>a.Caption>b.Caption ? 1 : -1);
    dsFieldsLkp.enableClientSideHandler( props.propertiesDataObject ? chooseFields.value.filter(r => !r.IsProp) : chooseFields.value );

    chooseFields.value.forEach(entry => {
        fieldTypesMapping[entry.FieldName] = entry.Type == "bool" ? "bit" : entry.Type;
    });
}

const filterItems = ref({type: "group", mode:"and",items:[]}); // createInitialObject instead?
const filterStringTextEdit = ref("");
const newOr = ref(false);

const loadBuilder = async ()=>{
    if (!chooseFields.value.length){
        await initiateFields();
    }
    if (props.filterString){
        var vData = await filterStringToFilterItems(props.filterString);
        filterItemsAfterLoad(vData);
        filterItems.value = vData;
    } else {
        filterItems.value = {type: "group", mode:"and",items:[]};
    }
    filterStringTextEdit.value = props.filterString;
}

watch(()=>props.filterString, loadBuilder, { immediate: true });

const getOperatorsList2 = (type,col)=>{
    //TODO: only list options null/not null for date/datetime for now
    let vType = fieldTypesMapping[col] ? fieldTypesMapping[col] : type;

    return getOperatorsList(vType)
}

const getType = (type, col)=>{
    return fieldTypesMapping[col] ? fieldTypesMapping[col] : type
}

const setTypeAndOperator = async (item, newField, orGroupIndex, isNewOr) => {
    if (item){
        if (item.valueType != fieldTypesMapping[newField]){
            item.valueType = fieldTypesMapping[newField];
            item.operator = getDefaultOperator(item.valueType);
            item.value = null;
        }
    } else if (!isNewOr){
        var newItemType = fieldTypesMapping[newField];
        //console.log(newItemType)

        if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items[orGroupIndex].items.push({type: "expression", column:newField,valueType:newItemType,operator:getDefaultOperator(newItemType), value: ""});
        } else {
            filterItems.value.items.push({type: "expression", column:newField,valueType:newItemType,operator:getDefaultOperator(newItemType), value: ""});
        }
    } else {
        var newItemType = newField ? fieldTypesMapping[newField] : null;
        var newOperator = newItemType ? getDefaultOperator(newItemType) : null;
        if (filterItems.value.mode == 'or'){//if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items.push({type: "group", mode:"and", items:[{type: "expression", column:newField,valueType:newItemType,operator:newOperator, value: ""}]});

        } else {
            var curCriteria = filterItemsToString(filterItems.value);
            var newCriteria = filterItemsToString({type: "expression", column:newField,valueType:newItemType,operator:newOperator, value: ""});

            var vData = await filterStringToFilterItems("("+curCriteria +") OR " + newCriteria);

            filterItemsAfterLoad(vData);
            filterItems.value = vData;
        }
        newOr.value = false;
    }
}

const deleteItem = (index,index2) => {
    if (index || index === 0){
        filterItems.value.items[index].items.splice(index2,1);
    } else {
        filterItems.value.items.splice(index2,1);
    }
    
}
const deleteGroup = (index) => {
    filterItems.value.items.splice(index,1);
}

const toggleTextualEdit = async ()=>{
    if (textualEdit.value){
        if (filterStringTextEdit.value){
            var vData = await filterStringToFilterItems(filterStringTextEdit.value);
            filterItemsAfterLoad(vData);
            filterItems.value = vData;
        } else {
            filterItems.value = {type: "group", mode:"and",items:[]};
        }
    } else {
        filterStringTextEdit.value = getFilterString(true);
    }
    textualEdit.value = !textualEdit.value;
}

const getFilterString = (isConvert) => {
    if ((!textualEdit.value && !forceTextualEdit.value) || isConvert){
        filterItemsBeforeSave(filterItems.value);
        var vFilter = filterItemsToString(filterItems.value);
        return vFilter
    } else {
        return filterStringTextEdit.value
    }
    
}
const filterItemsAfterLoad = (pFilterItems) => {

    if (pFilterItems.items){
        //console.log(pFilterItems.items);

        pFilterItems.items.forEach((data) => {
            if (data.type === "expression"){
                if (data.column){
                    if (fieldTypesMapping[data.column] == "string" && ["isnull","isnotnull"].indexOf(data.operator) > -1){
                        data.operator = (data.operator == "isnull" ? "isblank" : "isnotblank");
                    } else if (fieldTypesMapping[data.column] == "bit"){
                        data.operator = data.value ? "istrue" : "isfalse";
                    } else if (!data.value && data.operator == "notequals"){
                        data.operator = "isnotblank"
                    }
                    
                }
            }else if (data.items) {
                data.items.forEach((data2) => {
                    if (data2.column){
                        if (fieldTypesMapping[data2.column] == "string" && ["isnull","isnotnull"].indexOf(data2.operator) > -1){
                            data2.operator = (data2.operator == "isnull" ? "isblank" : "isnotblank");
                        } if (fieldTypesMapping[data2.column] == "bit"){
                            data2.operator = data2.value ? "istrue" : "isfalse";
                        } else if (!data2.value && data2.operator == "notequals"){
                            data2.operator = "isnotblank"
                        }
                        
                    }
                });
            }

        });
    }
}

const filterItemsBeforeSave = (pFilterItems) => {
    if (pFilterItems.items){
        let curItem, curItem2;
        for (let i=pFilterItems.items.length-1;i>=0;i--){
            curItem = pFilterItems.items[i];
            if (curItem.type === "expression"){
                if (!curItem.value && ["istrue", "isfalse","isblank", "isnotblank", "isnull", "isnotnull"].indexOf(curItem.operator) == -1){
                    pFilterItems.items.splice(i, 1);
                } else if (["inlist", "notinlist"].indexOf(curItem.operator) > -1 && curItem.value && curItem.value.constructor !== Array){
                    curItem.value = curItem.value.split(',').map(str => str.trim());
                } else if (["inlist", "notinlist"].indexOf(curItem.operator) == -1 && curItem.value && curItem.value.constructor === Array){
                    curItem.value = curItem.value.join();
                }
            } else if (curItem.items){
                for (let j=curItem.items.length-1;j>=0;j--){
                    curItem2 = curItem.items[j];
                    if (!curItem2.value && ["istrue", "isfalse","isblank", "isnotblank", "isnull", "isnotnull"].indexOf(curItem2.operator) == -1){
                        curItem.items.splice(j, 1);
                    } else if (["inlist", "notinlist"].indexOf(curItem2.operator) > -1 && curItem2.value && curItem2.value.constructor !== Array){
                        curItem2.value = curItem2.value.split(',').map(str => str.trim());
                    } else if (["inlist", "notinlist"].indexOf(curItem2.operator) == -1 && curItem2.value && curItem2.value.constructor === Array){
                        curItem2.value = curItem2.value.join();
                    }
                }
            }
        }
    }
}

const handleLookupSelection = (sel, item) => {
    let val = sel[props.lookups[item.column].Column];
    if (['notinlist','inlist'].indexOf(item.operator) > -1){
        item.value = item.value ? item.value + "," + val : val;
    } else {
        item.value = val;
    }
}
function extractFirstWordFromBrackets(input) {
    // Regular expression to match the first word inside square brackets
    const match = input.match(/\[(\w+)\]/);
    
    // Return the first captured group or null if no match is found
    return match ? match[1] : null;
}
function mergeContainsColumns(pObj){
    if (!pObj) return null;

    if (pObj.length) {
        let vContainItems = [];

        for (var i = 0; i < pObj.length; i++) {
                if (pObj[i] && pObj[i].column && pObj[i].column.toLowerCase().indexOf("isnull") > -1 && pObj[i].operator.indexOf("inlist") > -1) {
                    pObj[i].column = `${extractFirstWordFromBrackets(pObj[i].column)}`;
                }
                const vFoundItem = vContainItems.find(x=>x.column == pObj[i].column && x.operator == pObj[i].operator && pObj[i].operator == "contains");
                if(!vFoundItem){
                    vContainItems.push(pObj[i]);
                }else{
                    vFoundItem.value += " "+pObj[i].value;
                }
        }
        pObj = vContainItems;

        for (var i = 0; i < pObj.length; i++) {
            mergeContainsColumns(pObj[i]);
        }

    }
    if (pObj.items) {
        pObj.items = mergeContainsColumns(pObj.items);
    }

    return pObj;
}

async function filterStringToFilterItems(pString) {
    const vFilter =  await API.requestPost("/api/filtering/parseFilterStringToJson", JSON.stringify({
        filterString:pString
    }));
  
    return mergeContainsColumns(vFilter);
}

defineExpose({
    getFilterString, loadBuilder
})
</script>